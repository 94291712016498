import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import SectionContainer from '../components/section-container'
import Layout from '../components/layout'
import SEO from '../components/seo'

const AboutPage = ({ data }) => (
  <Layout>
    <SEO title="Par Diānu" />
    <SectionContainer branded>
      <div className="flex flex-wrap -mx-4">
        <div className="w-full sm:w-7/12 px-4 mb-8 sm:mb-0">
          <p className="text-justify mb-4">
            Diāna Grīnberga 1996. gadā beigusi Liepājas Pedagoģisko augstskolu
            un ieguvusi profesionālo bakalauru (pirmsskolas skolotāja), 2007.
            gadā beigusi Baltijas Starptautisko akadēmiju un ieguvusi jurista
            kvalifikāciju.
          </p>
          <p className="text-justify mb-4">
            Strādājusi Valsts Darba inspekcijā vairāk nekā desmit gadus, tai
            skaitā Juridiskās nodaļas vadītājas amatā. Pašlaik strādā SIA DTE
            (Darba Tiesību Eksperts) - sniedz juridiskos pakalpojumus un lasa
            lekcijas kompetences ietvaros.
          </p>
          <p className="text-justify mb-4">
            Brīvajā laikā Diāna spēlē bridžu un ir biedrības “Liepājas Sporta
            Bridža Klubs” valdes locekle, kā arī vairāk kā 30 gadus aktīvi
            līdzdarbojas Liepājas Tautas teātrī un ir valdes priekšsēdētāja
            Liepājas Tautas teātra aktieru biedrībā “Te un nekur citur”.
          </p>
          <p className="text-justify">
            Diāna izaudzinājusi dēlus Tomu un Raimondu un šobrīd kaķis - Picis
            audzina Diānu.
          </p>
        </div>
        <div className="w-full sm:w-5/12 px-4 flex items-center">
          <div className="w-full">
            <Img fluid={data.file.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </SectionContainer>
  </Layout>
)

export default AboutPage

export const query = graphql`
  query {
    file(relativePath: { eq: "diana_grinberga.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 650, base64Width: 100, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
